import style from 'styled-components';



export const Photo = style.img`
  width: 25vw;
  height: auto;
  border: 2px inset ${props => props.theme.pink};
  border-radius: 10px
`

export const Loading = style.div`
  background-image: url('homesite/src/assets/Loading.gif'});
`

export const Feed = style.div`
  overflow:scroll; 
  height: 33vh;
  width: 33vw;
  justify-content: center;
  align-items: center;
`
export const PhotoWrapper = style.div`
  width: 25vw;
  background-color: ${props => props.theme.blue};
  margin: auto;
`
export const PhotoText =  style.span`
  font-family: "Comic Sans MS", cursive, sans-serif;
  color: ${props => props.theme.yellow};
`