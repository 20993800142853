import React from 'react';
import routes from './routes';

import {  Sticky } from '../common';

import {  TOP_NAV } from '../common/constants';

export default props => {
  const routeButtons = routes
    .filter(({ locations }) => locations.includes(TOP_NAV))
    .map(({button}) => button);

  return(
    <Sticky className="well" style={{textAlign: 'center', zIndex: '999',minHeight: '5vh'}}>
      {routeButtons}
    </Sticky>

  )
}