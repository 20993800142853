import React from 'react';
import { Grid, GridItem } from 'styled-grid-responsive';

import { FacebookLike, InstagramFeed, Background  } from '../../common';

import dollarSignClipArt from '../../assets/dollarSignClipArt.png';

import { Header, InstagramHeader } from './styles';

export default props => {
  //  TODO: 
  //  Add email sign up form
  
  return (
    <Background imageUrl={dollarSignClipArt}>
      <Header> 
        $$$$$$$$$$$$$$$$ <br/>
        ONE DOLLAR BILL <br/>
        $$$$$$$$$$$$$$$$</Header>
      <Grid center>
        <GridItem col={1/3} >
          <InstagramHeader>SOME PICS I TOOK</InstagramHeader>
        </GridItem>
        <GridItem>
          <InstagramFeed />
        </GridItem>
      </Grid>
      <FacebookLike/>
    </Background> 
  )
}