import React, { useState, useEffect } from 'react';
import { INSTAGRAM_URL } from '../../constants';

import instagramHacker from './instagramHacker';
import { 
  Photo, 
  Loading, 
  Feed,
  PhotoWrapper,
  PhotoText,
} from './styles';

export default props => {
  const [photoData, setPhotoData] = useState([]);
  const [photosLoaded, setphotosLoaded] =useState(false);

  useEffect( () => {
    if(photoData.length === 0){
      instagramHacker().then(data => {
        console.log(data);
        setPhotoData(data);
        setphotosLoaded(true);
      });
    }
  });

  if(!photosLoaded){
    return <Loading/>
  }

  return (
    <Feed className="well btn">
      {photoData.map(({ node }, index) => {
        console.log(node);
        const caption = node.edge_media_to_caption.edges[0].node.text;
        const likes = node.edge_liked_by.count;
        return (
          <PhotoWrapper>  r
            <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
            <Photo key={index} src={node.display_url} alt={node.accessibility_caption}/>
            </a>
            <PhotoText>{`${caption} ❤️x ${likes} `}</PhotoText>
          </PhotoWrapper>
        )
      })}
    </Feed>
  )
  
  
}