import React from 'react';
import { Link } from "react-router-dom";
import { Emoji } from '../common';
import Button from 'react-bootstrap/Button';

import { StyledLink } from '../common';

import { 
  TOP_NAV, 
  BOTTOM_NAV, 
  SECRET_TAG,
  LEGAL_TAG,
  VARIANTS,
} from '../common/constants';

// import route components
import { 
  Billionaires, 
  Calendar, 
  Home, 
  Merch, 
  Music 
} from '../components';

import { PrivacyPolicy } from '../legal';

// TODO:
//   ADD Privacy Policy
//   ADD GDPR
//   ADD Trms and Conditions for email sign up
//   ADD /n to count to one billion unique visits

const routeConstants = [
  {
    title: 'Home',
    icon: '🏠',
    path: "/",
    component: () => <Home/>,
    exact: true,
    locations: [
      TOP_NAV,
      BOTTOM_NAV,
    ],
    tags: [],
    buttonStyle: {
      variant: VARIANTS.PRIMARY,
    },
    iconStyle: {
      variant: VARIANTS.DEFAULT
    },
  },
  {
    title: 'Music',
    icon: '🎵',
    path: "/music",
    component: () => <Music/>,
    locations: [
      TOP_NAV,
      BOTTOM_NAV,
    ],
    tags: [],
    buttonStyle: {
      variant: VARIANTS.PRIMARY,
    },
    iconStyle: {
      variant: VARIANTS.DEFAULT
    },
  },
  {
    title: 'Tour Dates',
    icon: '📆',
    path: "/calendar",
    component:() =>  <Calendar/>,
    locations: [
      TOP_NAV,
      BOTTOM_NAV,
    ],
    tags: [],
    buttonStyle: {
      variant: VARIANTS.PRIMARY,
    },
    iconStyle: {
      variant: VARIANTS.DEFAULT
    },
  },
  {
    title: 'Merch',
    icon: '🛒',
    path: "/merch",
    component: () => <Merch/>,
    locations: [
      TOP_NAV,
      BOTTOM_NAV,
    ],
    tags: [],
    buttonStyle: {
      variant: VARIANTS.PRIMARY,
    },
    iconStyle: {
      variant: VARIANTS.DEFAULT
    },
  },{
    title: 'Billionaires Club',
    icon: '🛒',
    path: "/n",
    component: () => <Billionaires/>,
    locations: [],
    tags: [ SECRET_TAG ],
    buttonStyle: {
      variant: VARIANTS.PRIMARY,
    },
    iconStyle: {
      variant: VARIANTS.DEFAULT
    },
  },{
    title: 'Privacy Policy',
    icon: 'Privacy Policy',
    path: "/privacy-policy",
    component: () => <PrivacyPolicy/>,
    locations: [BOTTOM_NAV],
    tags: [ LEGAL_TAG ],
    buttonStyle:  {
      variant: VARIANTS.INVERSE,
    },
    iconStyle: {
      variant: VARIANTS.INVERSE
    },
   
  },
];


const createIcon = ({ path, icon, iconStyle}, index) => {
  return (
    <Link to={path} key={index}><Emoji variant={iconStyle.variant}>{icon}</Emoji></Link>
  );
}

const createButton = ({ path, title, buttonStyle }, index) => {
  return (
    <Button  key={index} href={path} size="lg" variant={buttonStyle.variant}>{title}</Button>
  );
}

const createLink = (({ path, title },index) =>{
  return(
    <StyledLink to={path} key={index}>{title}</StyledLink>
  )
})

export default routeConstants.map((route, index) => {
  return {
    ...route,
    icon: createIcon(route, index),
    button: createButton(route, index),
    link: createLink(route, index),
  }
});