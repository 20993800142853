import styled from 'styled-components';

export default styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0; 
  flex-direction: row;
  margin: 0;
`



