import React from 'react';
import styled from 'styled-components';
import { Grid, GridItem } from 'styled-grid-responsive';

import routes from './routes';
import { StyledContainer } from '../common'

import {  BOTTOM_NAV, LEGAL_TAG } from '../common/constants';
import { Signature } from '../common/styled';

import { default as Attributions } from '../legal/Attributions';

export default props => {
  const routeButtons = routes
    .filter(({ locations, tags }) => locations.includes(BOTTOM_NAV) && !tags.includes(LEGAL_TAG))
    .map(({ icon }) => icon);

  const LegalLinks = routes
    .filter(({tags}) => tags.includes(LEGAL_TAG))
    .map(({ link }) => link);
  
  return(
      <DollarFooter>
        <Grid center>
          <GridItem col={1/2}>
            <StyledContainer>
              {routeButtons}
            </StyledContainer>
          </GridItem>
          <GridItem col={1/4}>
            <StyledContainer>
              {LegalLinks}
            </StyledContainer>
          </GridItem>
          <GridItem col={1}>
            <Signature/>
          </GridItem>
        </Grid>
      </DollarFooter>
  )
}

const DollarFooter = styled.div`
  justify-content: center;
  min-height: 100px;
  max-height: 25vh;
  background-image: url(https://media.giphy.com/media/yyN4OraJec8oM/giphy.gif);
  background-repeat: repeat;
  max-width: 100vw;
`
