import React from 'react';
import style from 'styled-components';

const Wrapper = style.div`
  width: 20wv;
  background-color: white;
  padding: 5px;
  position: absolute;
  bottom: 2px;
`

export default  () => {
  return ( 
    <Wrapper 
      className="fb-like btn" 
      data-href="https://www.facebook.com/1dollarbill/" 
      data-width="" 
      data-layout="standard" 
      data-action="like" 
      data-size="small" 
      data-show-faces="false" 
      data-share="true"
    />

  );
}