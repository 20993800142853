import React from 'react';

import { Grid, GridItem } from 'styled-grid-responsive';

import { 
  Background, 
  SpotifyPlayer, 
  FollowButton,
  SoundCloudPlayer
} 
from '../../common';

import singer from '../../assets/singer.jpg';
import checkOutTheseJamz from '../../assets/checkOutTheseJamz.gif';
import randomTunes from '../../assets/randomTunes.gif';

import { Header } from './style';

export default props => {
  // TODO:
  //   ADD iTunes links

  return (
    <Background imageUrl={singer}>
      <Grid center>
        <GridItem>
          <Header src={checkOutTheseJamz} />
        </GridItem>
        <GridItem>
          <SpotifyPlayer/>
        </GridItem>
        <GridItem>
          <FollowButton />
        </GridItem>
        <GridItem>
          <Header src={randomTunes} />
        </GridItem>
        <GridItem>
          <SoundCloudPlayer />
        </GridItem>
      </Grid>
    </Background>
    
  )
}