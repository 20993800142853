
// Static locations
export const TOP_NAV = "TopNav";
export const BOTTOM_NAV = "BottomNav";
export const SECRET_TAG = "secret_tag";
export const LEGAL_TAG = "legal_tag";
export const VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger",
  INFO: "info",
  LIGHT: "light",
  INVERSE: "inverse",
  LINK: "link",
  DEFAULT: "default"
};

export const ONEDOLLARBILL_ANCHOR = "onedollarbill.io";
export const INSTAGRAM_URL = 'https://instagram.com/1dollarbillmusic';
