import style from 'styled-components';

export const InstagramHeader = style.h4`
  color: ${props => props.theme.lightgreen};
  background: ${props => props.theme.blue};
  padding: 5px;
  border: 6px outset ${props => props.theme.lightgreen};
  width: 33vw;
`

export const Header = style.h1`
  font-family: 'CoreCircus', sans-serif;
  text-transform: uppercase;
  font-size: 5vw;
  text-align: center;
  line-height: 1;
  margin: 10vh;

  color: ${props => props.theme.pink};
  text-shadow: 1px 0px 0px ${props => props.theme.blue},
    0px 1px 0px ${props => props.theme.blue},
    2px 1px 0px ${props => props.theme.blue},
    1px 2px 0px ${props => props.theme.blue},
    3px 2px 0px ${props => props.theme.blue},
    2px 3px 0px ${props => props.theme.blue},
    4px 3px 0px ${props => props.theme.blue},
    3px 4px 0px ${props => props.theme.blue},
    5px 4px 0px ${props => props.theme.blue},
    4px 5px 0px ${props => props.theme.blue},
    6px 5px 0px ${props => props.theme.blue},
    5px 6px 0px ${props => props.theme.blue},
    7px 6px 0px ${props => props.theme.blue},
    6px 7px 0px ${props => props.theme.blue},
    8px 7px 0px ${props => props.theme.black},
    7px 8px 0px ${props => props.theme.black},
    9px 8px 0px ${props => props.theme.black},
    8px 9px 0px ${props => props.theme.black},
    10px 9px 0px ${props => props.theme.black},
    9px 10px 0px ${props => props.theme.black},
    11px 10px 0px ${props => props.theme.black},
    10px 11px 0px ${props => props.theme.black};
  &:before,
  &:after {
    content: attr(data-heading);
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 0;
  }
  &:before {
    color: ${props => props.theme.white};
    width: 100%;
    z-index: 5;
    font-family: 'CoreCircus2DIn';
    font-weight: normal;
  }
  &:after {
    z-index: -1;
    text-shadow: 
      -1px -1px 0 ${props => props.theme.white},
      1px -1px 0 ${props => props.theme.white}, 
      -1px 1px 0 ${props => props.theme.white}, 
      1px 1px 0 ${props => props.theme.white}, 
      -3px 3px 2px #6c9d9e, 
      -5px 5px 2px #6c9d9e,
      -7px 7px 2px #6c9d9e,
      -8px 8px 2px #6c9d9e,
      -9px 9px 2px #6c9d9e,
      -11px 11px 2px #6c9d9e;
  }
`