import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from 'styled-components'; 

import { routes, TopNav, BottomNav }from './Navigation';
import { FlexWrapper } from './common';

const theme = {
  red: "red",
  pink: "fuchsia",
  yellow: "yellow",
  green: "green",
  lightgreen: "lime",
  blue: "blue",
  lightblue: "aqua",
  black: "black",
  white: "white",
}



const App = props => {
  const routeComponents = routes.map(({path, exact, component}, index) => {
    return (
      <Route 
        key={index}
        path={path} 
        exact={exact}
        component={component}
      />
    );
  })

  return(
  <ThemeProvider theme={theme}>
   <Router>
        <TopNav/>
   <FlexWrapper>
        {routeComponents}
        <BottomNav/>
      </FlexWrapper>
    </Router>
  </ThemeProvider>
  );
}

export default App;
