import React from 'react';
import style from 'styled-components';


const Wrapper = style.div`
  width: 50vw;
  margin: auto;
`

export default () => {
  return(
    <Wrapper>
      <iframe title='soundcloud-player' width="100%" height="300" scrolling="yes" frameborder="yes" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/18894021&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    </Wrapper>
   );
}