import axios from 'axios';

export default async () => {
  let photoData;
  try {
    const { data } = await axios.get('https://www.instagram.com/1dollarbillmusic/');
    const sharedData = parseSharedDataObject(data);
    photoData = getTimelinePhotoData(sharedData);
  } catch (error) {
    console.error(error);
  }

  return photoData
}

const parseSharedDataObject = data => {
  const sharedDataString = data
    .split(`<script type="text/javascript">window._sharedData =`)[1]
    .split(`;</script>`)[0];
  
    return JSON.parse(sharedDataString);
}

const getTimelinePhotoData = sharedData => {
  return sharedData.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges;
}