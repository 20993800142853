import React from 'react';
import styled from 'styled-components';

const AttributionWrapper = styled.div`
  height: 0.4rem;
  opacity: 0.5;
  color: darkslateblue;
`

const Attribution = styled.a.attrs(({ href }) => ({
  href,
  target:"_blank"
}))`
  color: darkcyan;
`

const attributionsData =  [

]

export default props => {
  const attributions =  attributionsData.map(({preText, linkText, href }, index) => {
    return(
      <AttributionWrapper key={index}>
        {preText}
        <Attribution href={href}>{linkText}</Attribution>
      </AttributionWrapper>
    );
  });

  return(
    <div>
      {attributions}
    </div>
  )
}
